import {
  Menu,
  Layout,
  AppBar,
  UserMenu,
  useGetIdentity,
} from "react-admin";
import { Typography } from "@mui/material";

const MyMenu = () => {
  return (
    <Menu>
      <Menu.ResourceItems />
      {/* <Menu.Item to="/ClockingPage" primaryText="ClockingPage" /> */}
    </Menu>
  );
};

const MyAppBar = (props) => {
  const { identity } = useGetIdentity();

  return (
    <AppBar {...props} userMenu={<UserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        style={{ flex: 1, textAlign: "center" }}
      >
        {identity ? identity.email : "No email"}
      </Typography>
    </AppBar>
  );
};

export const MyLayout = ({ children }) => (
  <Layout menu={MyMenu} appBar={MyAppBar}>
    {children}
  </Layout>
);
