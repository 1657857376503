import React, { useEffect, useState, Fragment } from "react";
import QRCode from "qrcode.react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  TextField,
  DateField,
  useDataProvider,
  NumberField,
  NumberInput,
  BulkDeleteButton,
  BulkExportButton,
  useNotify,
  Toolbar,
  SaveButton,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  DateTimeInput,
  useGetIdentity,
  FormDataConsumer,
  useSimpleFormIteratorItem,
} from "react-admin";
import { Button, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";

const ClockInOutButtons = () => {
  const { setValue } = useFormContext();
  const { index } = useSimpleFormIteratorItem();
  const notify = useNotify();

  const handleClockIn = () => {
    const clockInTime = new Date().toISOString();
    setValue(`clockEvents.${index}.clockIn`, clockInTime);
    notify("Clocked in at " + clockInTime, { type: "info" });
  };

  const handleClockOut = () => {
    const clockOutTime = new Date().toISOString();
    setValue(`clockEvents.${index}.clockOut`, clockOutTime);
    notify("Clocked out at " + clockOutTime, { type: "info" });
  };

  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Button variant="contained" color="primary" onClick={handleClockIn}>
        Clock In
      </Button>
      <Button variant="contained" color="secondary" onClick={handleClockOut}>
        Clock Out
      </Button>
    </Box>
  );
};

const PostBulkActionButtons = () => (
  <Fragment>
    <BulkExportButton />
    <BulkDeleteButton />
  </Fragment>
);

const UseUsers = () => {
  const [users, setUsers] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await dataProvider.getList("users", {
          pagination: { page: 1, perPage: 100 }, // Adjust as needed
          sort: { field: "name", order: "ASC" },
        });
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [dataProvider]);

  return users;
};

const OrderQRCode = () => {
  const record = useRecordContext(); // This will get the current record (order) in context

  if (!record) return null; // Ensure there's a record before trying to generate a QR code

  const orderUrl = `/api/#/orders/${record.id}`;
  console.log(orderUrl);

  return (
    <div>
      <h3>Scan this QR code to view the order:</h3>
      <QRCode value={orderUrl} />
    </div>
  );
};

const orderFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="Status"
    source="status"
    choices={[
      { id: "pending", name: "Pending" },
      { id: "in_progress", name: "In Progress" },
      { id: "completed", name: "Completed" },
    ]}
  />,
  <TextInput label="Customer Name" source="customerName" />,
  <TextInput label="Item Name" source="itemName" />,
  <DateInput label="Due Date" source="due_date" />,
];

export const OrderList = (props) => (
  <List {...props} filters={orderFilters}>
    <Datagrid rowClick="edit" bulkActionButtons={<PostBulkActionButtons />}>
      <TextField source="id" />
      <OrderQRCode source="id" />
      <TextField source="customerName" />
      <TextField source="description" />
      <TextField source="purchaseOrder" />
      <NumberField source="quantity" />
      <TextField source="itemName" />
      <TextField source="status" />
      <DateField source="due_date" />
    </Datagrid>
  </List>
);

export const OrderEdit = () => {
  const users = UseUsers();

  return (
    <Edit>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled />
        <TextInput source="customerName" />
        <TextInput source="description" />
        <TextInput source="purchaseOrder" />
        <NumberInput source="quantity" />
        <TextInput source="itemName" />
        <SelectInput
          source="status"
          choices={[
            { id: "pending", name: "Pending" },
            { id: "in_progress", name: "In Progress" },
            { id: "completed", name: "Completed" },
          ]}
        />
        <DateInput source="due_date" />
        <ArrayInput source="clockEvents">
          <SimpleFormIterator inline>
            <ClockInOutButtons />
            <SelectInput
              source="assign_to"
              choices={users.map((user) => ({
                id: user.fullName,
                name: user.fullName,
              }))}
            />
            <SelectInput
              source="department"
              label="Department"
              choices={[
                { id: "Fabrication F2", name: "Fabrication F2" },
                { id: "Fabrication F16", name: "Fabrication F16" },
                { id: "Sheet Metal F12", name: "Sheet Metal F12" },
                { id: "Sheet Metal F12A", name: "Sheet Metal F12A" },
                { id: "CNC F48", name: "CNC F48" },
                { id: "CNC F53", name: "CNC F53" },
                { id: "CNC F32", name: "CNC F32" },
                { id: "CNC F32A", name: "CNC F32A" },
                { id: "Painting F19", name: "Painting F19" },
                { id: "Outsourcing", name: "Outsourcing" },
              ]}
            />
            <DateTimeInput source="clockIn" label="Clock In Time" />
            <DateTimeInput source="clockOut" label="Clock Out Time" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const CustomAddButton = ({ onAdd }) => {
  return (
    <Button variant="contained" color="primary" onClick={onAdd}>
      Add New Clock Event
    </Button>
  );
};

const CustomSimpleFormIterator = ({ identity }) => {
  const { setValue, getValues } = useFormContext();

  const handleAddClockEvent = () => {
    const currentEvents = getValues("clockEvents") || [];
    const newEvent = {
      assign_to: identity.fullName,
      department: identity.department,
      clockIn: "",
      clockOut: "",
    };

    setValue("clockEvents", [...currentEvents, newEvent]);
  };

  return (
    <SimpleFormIterator
      inline
      disableRemove
      disableClear
      disableReordering
      addButton={<CustomAddButton onAdd={handleAddClockEvent} />}
    >
      <FormDataConsumer>
        {({ scopedFormData }) =>
          scopedFormData &&
          scopedFormData.assign_to === identity.fullName ? (
            <>
            <ClockInOutButtons/>
              <DateTimeInput source="clockIn" label="Clock In Time" />
              <DateTimeInput source="clockOut" label="Clock Out Time" />
              <TextInput
                source="assign_to"
                defaultValue={identity.fullName}
                readOnly
              />
              <TextInput
                source="department"
                defaultValue={identity.department}
                readOnly
              />
            </>
          ) : null
        }
      </FormDataConsumer>
    </SimpleFormIterator>
  );
};

export const OrderEditUser = () => {
  const { data: identity, isLoading } = useGetIdentity();
  if (isLoading) return <>Loading...</>;

  return (
    <Edit>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton alwaysEnable />
          </Toolbar>
        }
      >
        <TextInput source="customerName" label="Customer name" readOnly />
        <TextInput source="description" readOnly />
        <TextInput source="purchaseOrder" readOnly />
        <NumberInput source="quantity" readOnly />
        <TextInput source="itemName" readOnly />
        <TextInput source="status" readOnly />
        <DateInput source="due_date" readOnly />
        <ArrayInput source="clockEvents">
          <CustomSimpleFormIterator identity={identity} />
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export const OrderCreate = () => {
  const users = UseUsers();

  return (
    <Create>
      <SimpleForm>
        <TextInput source="customerName" />
        <TextInput source="description" />
        <TextInput source="purchaseOrder" />
        <NumberInput source="quantity" />
        <TextInput source="itemName" />
        <SelectInput
          source="status"
          choices={[
            { id: "pending", name: "Pending" },
            { id: "in_progress", name: "In Progress" },
            { id: "completed", name: "Completed" },
          ]}
        />
        <DateInput source="due_date" />
        <ArrayInput source="clockEvents">
          <SimpleFormIterator inline>
            <ClockInOutButtons />
            <SelectInput
              source="assign_to"
              choices={users.map((user) => ({
                id: user.fullName,
                name: user.fullName,
              }))}
            />
            <SelectInput
              source="department"
              label="Department"
              choices={[
                { id: "Fabrication F2", name: "Fabrication F2" },
                { id: "Fabrication F16", name: "Fabrication F16" },
                { id: "Sheet Metal F12", name: "Sheet Metal F12" },
                { id: "Sheet Metal F12A", name: "Sheet Metal F12A" },
                { id: "CNC F48", name: "CNC F48" },
                { id: "CNC F53", name: "CNC F53" },
                { id: "CNC F32", name: "CNC F32" },
                { id: "CNC F32A", name: "CNC F32A" },
                { id: "Painting F19", name: "Painting F19" },
                { id: "Outsourcing", name: "Outsourcing" },
              ]}
            />
            <DateTimeInput source="clockIn" label="Clock In Time" />
            <DateTimeInput source="clockOut" label="Clock Out Time" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
