import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";

const apiUrl = "/api";
const httpClient = fetchUtils.fetchJson;

const dataProvider = jsonServerProvider(apiUrl, httpClient);

const CustomDataProvider = {
  ...dataProvider,
  getList: (resource, params) =>
    dataProvider.getList(resource, params).then((response) => {
      // Ensure response.json is defined
      const json = response.json || {};

      // Assuming JSON:API provides pagination info in the meta field
      const total = json.meta ? json.meta.total : response.data.length;

      // Clone response to include X-Total-Count header
      const headers = new Headers(response.headers);
      headers.set("X-Total-Count", total);
      headers.set("Access-Control-Expose-Headers", "X-Total-Count");

      return {
        ...response,
        headers,
        total,
      };
    }),
};

export default CustomDataProvider;
