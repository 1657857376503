import { fetchUtils } from "react-admin";

const apiUrl = "/api";
const httpClient = fetchUtils.fetchJson;

const authProvider = {
  login: ({ username, password }) => {
    const url = `${apiUrl}/users?username=${username}`;
    return httpClient(url).then(({ json }) => {
      if (json.length === 0 || json[0].password !== password) {
        return Promise.reject("Invalid username or password");
      }
      localStorage.setItem(
        "auth",
        JSON.stringify({
          id: json[0].id,
          fullName: json[0].fullName, // Adjust according to your data structure
          email: json[0].email,
          department: json[0].department,
        })
      );
      localStorage.setItem("role", json[0].role);
      return Promise.resolve();
    });
  },

  logout: () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("role");
    return Promise.resolve();
  },

  checkAuth: () => {
    return localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject("User not authenticated");
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      localStorage.removeItem("role");
      return Promise.reject("Unauthorized or forbidden");
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role
      ? Promise.resolve(role)
      : Promise.reject("No permissions found");
  },

  getIdentity: () => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth) {
        const { id, fullName, email, department } = auth;
        return Promise.resolve({ id, fullName, email, department });
      }
      return Promise.reject("No auth data found");
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
