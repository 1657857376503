import * as React from "react";
import { Fragment } from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  TextField,
  BulkDeleteButton,
  BulkExportButton,
  usePermissions,
  useNotify,
  useRedirect,
} from "react-admin";

const PostBulkActionButtons = () => (
  <Fragment>
    <BulkExportButton />
    <BulkDeleteButton />
  </Fragment>
);

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput
    label="Role"
    source="role"
    defaultValue="admin"
    choices={[
      { id: "admin", name: "admin" },
      { id: "user", name: "user" },
    ]}
  />,
  <SelectInput
    source="department"
    label="Department"
    choices={[
      { id: "Fabrication F2", name: "Fabrication F2" },
      { id: "Fabrication F16", name: "Fabrication F16" },
      { id: "Sheet Metal F12", name: "Sheet Metal F12" },
      { id: "Sheet Metal F12A", name: "Sheet Metal F12A" },
      { id: "CNC F48", name: "CNC F48" },
      { id: "CNC F53", name: "CNC F53" },
      { id: "CNC F32", name: "CNC F32" },
      { id: "CNC F32A", name: "CNC F32A" },
      { id: "Painting F19", name: "Painting F19" },
      { id: "Outsourcing", name: "Outsourcing" },
    ]}
  />,
];

export const UserList = (props) => {
  const { permissions } = usePermissions();

  if (permissions !== "admin") {
    return null; // Optionally render a placeholder or loading spinner
  }
  return (
    <List {...props} filters={postFilters}>
      <Datagrid rowClick="edit" bulkActionButtons={<PostBulkActionButtons />}>
        <TextField source="id" label="ID" />
        <TextField source="fullName" label="fullName" />
        <TextField source="username" label="Username" />
        <TextField source="password" label="Password" type="password" />
        <TextField source="email" label="Email" />
        <TextField source="phone" label="Phone" />
        <TextField source="department" label="Department" />
        <TextField source="role" label="role" />
      </Datagrid>
    </List>
  );
};

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" label="ID" />
      <TextInput source="fullName" label="fullName" />
      <TextInput source="username" label="Username" />
      <TextInput source="password" label="Password" type="password" />
      <TextInput source="email" label="Email" />
      <TextInput source="phone" label="Phone" />
      <SelectInput
        source="department"
        label="Department"
        choices={[
          { id: "Fabrication F2", name: "Fabrication F2" },
          { id: "Fabrication F16", name: "Fabrication F16" },
          { id: "Sheet Metal F12", name: "Sheet Metal F12" },
          { id: "Sheet Metal F12A", name: "Sheet Metal F12A" },
          { id: "CNC F48", name: "CNC F48" },
          { id: "CNC F53", name: "CNC F53" },
          { id: "CNC F32", name: "CNC F32" },
          { id: "CNC F32A", name: "CNC F32A" },
          { id: "Painting F19", name: "Painting F19" },
          { id: "Outsourcing", name: "Outsourcing" },
        ]}
      />
      <SelectInput
        source="role"
        label="Role"
        choices={[
          { id: "admin", name: "admin" },
          { id: "user", name: "user" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const UserCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="id" label="ID" />
        <TextInput source="fullName" label="fullName" />
        <TextInput source="username" label="Username" />
        <TextInput source="password" label="Password" type="password" />
        <TextInput source="email" label="Email" />
        <TextInput source="phone" label="Phone" />
        <SelectInput
          source="department"
          label="Department"
          choices={[
            { id: "Fabrication F2", name: "Fabrication F2" },
            { id: "Fabrication F16", name: "Fabrication F16" },
            { id: "Sheet Metal F12", name: "Sheet Metal F12" },
            { id: "Sheet Metal F12A", name: "Sheet Metal F12A" },
            { id: "CNC F48", name: "CNC F48" },
            { id: "CNC F53", name: "CNC F53" },
            { id: "CNC F32", name: "CNC F32" },
            { id: "CNC F32A", name: "CNC F32A" },
            { id: "Painting F19", name: "Painting F19" },
            { id: "Outsourcing", name: "Outsourcing" },
          ]}
        />
        <SelectInput
          source="role"
          label="Role"
          choices={[
            { id: "admin", name: "admin" },
            { id: "user", name: "user" },
          ]}
        />{" "}
      </SimpleForm>
    </Create>
  );
};
