import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    TextField,
    NumberField,
    NumberInput,
  } from "react-admin";
  
export const manufacturingItemList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <NumberField source="quantity" />
        </Datagrid>
    </List>
);

export const manufacturingItemEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="id" disabled />
                <TextInput source="name" />
                <TextInput source="description" />
                <NumberInput source="quantity" />
            </SimpleForm>
        </Edit>
    );
};

// Create View for Manufacturing Items
export const manufacturingItemCreate = () => {
    return (
        <Create>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="description" />
                <NumberInput source="quantity" />
            </SimpleForm>
        </Create>
    );
};