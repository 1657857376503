import React, { useState } from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import authProvider from "./authProvider";
import customDataProvider from "./dataProvider";
import { UserList, UserCreate, UserEdit } from "./components/users";
import {
  OrderList,
  OrderCreate,
  OrderEdit,
  OrderEditUser,
} from "./components/orders";
import {
  manufacturingItemList,
  manufacturingItemEdit,
  manufacturingItemCreate,
} from "./components/mis";
import { Route } from "react-router-dom";
import ClockingPage from "./ClockingPage";
import { MyLayout } from "./Mylayout";

function App() {
  return (
    <Admin
      dataProvider={customDataProvider}
      authProvider={authProvider}
      layout={MyLayout}
    >
      {(permissions) => (
        <>
          <Resource
            name="orders"
            list={OrderList}
            edit={permissions === "admin" ? OrderEdit : OrderEditUser}
            create={permissions === "admin" ? OrderCreate : null}
          />
          {permissions === "admin" ? (
            <Resource
              name="users"
              list={UserList}
              edit={UserEdit}
              create={UserCreate}
            />
          ) : null}
          {permissions === "admin" ? (
            <Resource
              name="manufacturing_items"
              list={manufacturingItemList}
              edit={manufacturingItemEdit}
              create={manufacturingItemCreate}
            />
          ) : null}
        </>
      )}
      {/* <CustomRoutes>
        <Route path="/ClockingPage" element={<ClockingPage />} />
      </CustomRoutes> */}
    </Admin>
  );
}

export default App;
